import React from "react";

const ExamFullView = () => {
  return (
    <section className="p-4">
      <h2 className="text-primary font-bold mb-4">Exam Weekly View</h2>
      <p>Exam feature coming soon</p>
    </section>
  );
};

export default ExamFullView;
