import React from 'react';

const ExamFilters = () => {
  return (
    <aside className="bg-offwhite p-4">
      <h2 className="text-primary font-bold mb-4">Exam Filters</h2>
      <p>Exam feature coming soon</p>
    </aside>
  );
};

export default ExamFilters;
